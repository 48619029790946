//  When I wrote this code, only God and I knew what it was. Now, only God knows!
<!--
 * @Description:
 * @Author: yjp
 * @Date: 2020-03-28
 * @LastEditTime: 2020-05-14 16:51:34
 * @FilePath: /src/pages/index/views/orderList/invoice/invoiceApply.vue
 -->

<template>
  <!-- //  data: applyIndexList,totalCount:totalCount,loading:loading -->
  <div>
    <tableApply
      :apply-list-prop="{type: 'FP'}"
      @subForm="subForm"
    />
  </div>
</template>

<script type="text/ecmascript-6">
// import {POST_ORDER_LIST} from '@/pages/index/actions/api';

import tableApply from '../components/tableApply';

export default {
  components: {
    tableApply,
  },
  props: {},
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  updated() {
  },
  methods: {
    // 子组件显示表单 invoiceForm
    subForm(type, obj) {
      this.$router.push({
        path: '/order/invoice/invoiceForm',
        name: 'invoiceFormPage',
        params: {
          ...obj,
        },
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
